import React from 'react';

class Header extends React.Component {
  render() {
    const headings = this.props.headings
    const links = this.props.links;
    const active = this.props.active

    return (
      <div className='Header'>
          <div className='DisplayPic'>
            <img src='Images/MyImage3.jpg' alt='MyImage1'/>
          </div>
          <div className='Heading'>
            <h1>Anand Kumar</h1>
          </div>
          <div className='List'>
            {headings.map((item, index)=> {
              return <div key={index} className={item.title} onClick={active} style={{cursor:"pointer"}}>{item.component} {item.title}</div>
            })}
          </div>
          <div className='SocialMedia'>
            {links.map((item, index)=> {
              return <div key={index}><a href={item.anchor} style={{color:"whitesmoke"}}>{item.component}</a></div>
            })}
          </div>
      </div>
    )
  }
}

export default Header