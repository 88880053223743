import React from 'react';

class Contact extends React.Component {
  render() {
    return (
      <div className='ContactContent'>
        <div>
          <h1 style={{color:"rgb(78, 78, 165)"}}>Contact</h1>
        </div>
        <div className='ContactLinks' style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            {this.props.contactDetails.map((item,index)=> {
                return <div key={index} className={item.title}>
                            <a href={item.link} style={{textDecoration: "none"}} target={"_blank"} rel={"noreferrer"}>
                                <h2 style={{color:"whitesmoke"}}>{item.component}&nbsp;&nbsp;&nbsp;{item.link}</h2>
                            </a>
                        </div>
            })}
        </div>
      </div>
    )
  }
}

export default Contact