import React from 'react';
import {BiChevronsRight} from 'react-icons/bi'

class About extends React.Component {
  render() {
    const activeAbout = []
    
    if(this.props.activeAbout === 0){
      activeAbout.push(<HomeAbout1 key={0}/>)
    }
    if(this.props.activeAbout === 'AboutMe'){
      activeAbout.push(<HomeAbout1 key={0}/>)
    }
    if(this.props.activeAbout === 'PersonalDetails'){
      activeAbout.push(<HomeAbout2 key={1} personalDetails={this.props.personalDetails}/>)
    }

    return (
      <div className='AboutContent'>
        <div>
          <h1 style={{color:"rgb(78, 78, 165)"}}>About Me</h1>
        </div>
        <div className='AboutContent2'>
          <div>
            <img src='Images/MyImage3.jpg' alt='MyImage1'/>
          <div className='AboutRight'>
            <div className='AboutButton'>
              <button className='AboutMe' onClick={this.props.onClick}>About Me</button>
              <button className='AboutMe' onClick={this.props.onClick}>Personal Details</button><br/>
            </div>
            {activeAbout}
          </div>  
          </div>                  
        </div>
      </div>
    )
  }
}

export default About

class HomeAbout1 extends React.Component {
  render() {
    return (
        <div className='AboutParagraph'>
            <h2 style={{textDecoration:"underline"}}>Who Am I:-</h2>
            <p>Hello Everyone, I am AnandKumar.</p>
            <p>I finished my B.Tech(Mech) degree in Karunya Institute of</p>
            <p>Technology & Sciences. I am always dedicated & determined </p>
            <p>towards my responsiblities. I always have Eagerness to</p>
            <p>learn Something new & try Something new.</p>
            <p></p>
        </div>
    )
  }
}

class HomeAbout2 extends React.Component {
  render() {
    return (
        <div className='AboutParagraph' style={{position:"relative", right:"0px"}}>
              <h2 style={{textDecoration:"underline"}}>Personal Details:-</h2>
              {this.props.personalDetails.map((item, index)=>{
                return <p key={index}><b style={{fontSize:"18px"}}>{item.title}</b> <b style={{color:"rgb(78, 78, 165)"}}><BiChevronsRight/></b> {item.content}</p>
              })}
        </div>
   )
  }}
