import React from 'react'

class Gallery extends React.Component {

  render() {
    const onMouseEnter = this.props.onMouseEnter;
    const onMouseOut = this.props.onMouseOut;
    const onClick = this.props.onClick;
    const gallery1 = this.props.gallery1;
    const gallery2 = this.props.gallery2;
    const gallery3 = this.props.gallery3;

    const activeGallery = []

    if(this.props.activeGallery === 'Graduation'){
      activeGallery.push(<Graduation key={0} gallery1={gallery1}/>)
    }
    if(this.props.activeGallery === 'Travel1'){
      activeGallery.push(<Travel1 key={1} gallery2={gallery2}/>)
    }
    if(this.props.activeGallery === 'Travel2'){
      activeGallery.push(<Travel2 key={2} gallery3={gallery3}/>)
    }

    return (
      <div className='GalleryContent'>
        <div>
          <h1 style={{color:"rgb(78, 78, 165)"}}>Gallery</h1>
        </div>
        <div className='Gallery1'>
            <div className='Graduation' 
            onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} onClick={onClick}>
                   {this.props.onHover1 ? "Graduation": ""}
            </div>
            <div className='Travel1' 
            onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} onClick={onClick}>
                    {this.props.onHover2 ? "Goa": ""}
            </div>
            <div className='Travel2'
            onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} onClick={onClick}>
                    {this.props.onHover3 ? "Ooty": ""}
            </div>
        </div>
        <div className='MainGallery'>
          {activeGallery}
        </div>
      </div>
    )
  }
}

export default Gallery

class Graduation extends React.Component{
  render() {
    return(
      <div className='GalleryMe'>
        {this.props.gallery1.map((item, index)=>{
          return <div className='Gallery2' key={index}>
                    <span>
                      <img src={item.src} alt={item.title} className='Images1'/>
                    </span>
                 </div>
        })}
      </div>
    )
  }
}

class Travel1 extends React.Component{
  render() {
    return(
      <div className='GalleryMe'>
        {this.props.gallery2.map((item, index)=>{
          return <div className='Gallery2' key={index}>
                    <span>
                      <img src={item.src} alt={item.title} className='Images1'/>
                    </span>
                 </div>
        })}
      </div>
    )
  }
}

class Travel2 extends React.Component{
  render() {
    return(
      <div className='GalleryMe'>
        {this.props.gallery3.map((item, index)=>{
          return <div className='Gallery2' key={index}>
                    <span>
                      <img src={item.src} alt={item.title} className='Images1'/>
                    </span>
                 </div>
        })}
      </div>
    )
  }
}