import React from 'react';
import Header from './Components/Header';
import './App.css';
import {AiFillHome} from 'react-icons/ai';
import {BsFillPersonFill} from 'react-icons/bs';
import {BsImages} from 'react-icons/bs';
import {GrMail} from 'react-icons/gr';
import {FaFacebook} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import {FaGithub} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import Home from './Components/Home';
import About from './Components/About';
import Gallery from './Components/Gallery';
import Contact from './Components/Contact';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      headings:[],
      links:[],
      activePage:0,
      activeAbout:0,
      personalDetails:[],
      activeGallery:0,
      gallery1:[],
      gallery2:[],
      gallery3:[],
      onHover1: false,
      onHover2: false,
      onHover3: false,
      contactDetails: []
    }

    this.onClick = this.onClick.bind(this);
    this.activeAbout = this.activeAbout.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.selectGallery = this.selectGallery.bind(this);

  }

  onClick(e){
    if(e.target.className === 'Home'){
      this.setState({activePage: "Home"})
    }
    if(e.target.className === 'About'){
      this.setState({activePage: "About"})
    }
    if(e.target.className === 'Gallery'){
      this.setState({activePage: "Gallery"})
    }
    if(e.target.className === 'Contact'){
      this.setState({activePage: "Contact"})
    }
  }

  activeAbout(e){
    if(e.target.innerText === "About Me"){
      this.setState({activeAbout: "AboutMe"})
    }
    if(e.target.innerText === "Personal Details"){
      this.setState({activeAbout: "PersonalDetails"})
    }
  }

  onMouseEnter(e){
    if(e.target.className === 'Graduation' && !this.state.onHover1){
      this.setState({onHover1: true})
    }
    if(e.target.className === 'Travel1' && !this.state.onHover2){
      this.setState({onHover2: true})
    }
    if(e.target.className === 'Travel2' && !this.state.onHover3){
      this.setState({onHover3: true})
    }
  }

  onMouseOut(e){
    if(e.target.className === 'Graduation' && this.state.onHover1){
      this.setState({onHover1: false})
    }
    if(e.target.className === 'Travel1' && this.state.onHover2){
      this.setState({onHover2: false})
    }
    if(e.target.className === 'Travel2' && this.state.onHover3){
      this.setState({onHover3: false})
    }
  }

  selectGallery(e){
    if(e.target.className === 'Graduation'){
      this.setState({activeGallery: "Graduation"})
    }
    if(e.target.className === 'Travel1'){
      this.setState({activeGallery: "Travel1"})
    }
    if(e.target.className === 'Travel2'){
      this.setState({activeGallery: "Travel2"})
    }
  }

  componentDidMount(){
    this.setState({
      headings:[{
        title:"Home",
        component:<AiFillHome/>
      },
      {
        title:"About",
        component:<BsFillPersonFill/>
      },
      {
        title:"Gallery",
        component:<BsImages/>
      },
      {
        title:"Contact",
        component:<GrMail/>
      }]})
    this.setState({personalDetails:[
      {
        title: "Name",
        content: "AnandKumar"
      },
      {
        title: "Age",
        content: "24"
      },
      {
        title: "Degree",
        content: "B.Tech"
      },
      {
        title: "Languages Known",
        content: "Tamil & English"
      },
      {
        title: "IT SKILLS",
        content: "HTML, CSS, JavaScript, ReactJs"
      }
    ]})
    this.setState({links:[
      {
        component:<FaFacebook/>,
        anchor:'https://www.facebook.com/anandkumar2612'
      }, 
      {
        component:<FaTwitter/>,
        anchor:'https://twitter.com/anandkumar2612'
      },
      {
        component:<FaInstagram/>,
        anchor:'https://www.instagram.com/innocent_._idiot/'
      },
      {
        component:<FaGithub/>,
        anchor:'https://github.com/Anand-kumar-26'
      }, 
      {
        component:<FaLinkedin/>,
        anchor:'https://www.linkedin.com/in/anand-kumar-3619a3157/'
      }]})
    this.setState({gallery1:[
      {
        src:"Images/Graduation/Image1.JPG", 
        title: "Image1"
      },
      {
        src:"Images/Graduation/Image2.JPG", 
        title: "Image2"
      },
      {
        src:"Images/Graduation/Image3.JPG", 
        title: "Image3"
      },
      {
        src:"Images/Graduation/Image4.JPG", 
        title: "Image4"
      },
      {
        src:"Images/Graduation/Image5.JPG", 
        title: "Image5"
      },
      {
        src:"Images/Graduation/Image6.JPG", 
        title: "Image6"
      },
      {
        src:"Images/Graduation/Image7.JPG", 
        title: "Image7"
      },
      {
        src:"Images/Graduation/Image8.JPG", 
        title: "Image8"
      },
      {
        src:"Images/Graduation/Image9.JPG", 
        title: "Image9"
      }
    ]})
    this.setState({gallery2:[
      {
        src:"Images/Goa/Image1.JPG", 
        title: "Image1"
      },
      {
        src:"Images/Goa/Image2.JPG", 
        title: "Image2"
      },
      {
        src:"Images/Goa/Image3.JPG", 
        title: "Image3"
      },
      {
        src:"Images/Goa/Image4.JPG", 
        title: "Image4"
      },
      {
        src:"Images/Goa/Image5.JPG", 
        title: "Image5"
      },
      {
        src:"Images/Goa/Image6.JPG", 
        title: "Image6"
      }
    ]})
    this.setState({gallery3:[
      {
        src:"Images/Ooty/Image1.jpg", 
        title: "Image1"
      },
      {
        src:"Images/Ooty/Image2.jpg", 
        title: "Image2"
      },
      {
        src:"Images/Ooty/Image5.jpg", 
        title: "Image5"
      }
    ]})
    this.setState({contactDetails:[
      {
        title:"ContactLink1",
        link: 'https://www.linkedin.com/in/anand-kumar-3619a3157/',
        component: <FaLinkedin/>,
      },
      {
        title:"ContactLink2",
        link: 'https://github.com/Anand-kumar-26',
        component: <FaGithub/>,
      },
      {
        title:"ContactLink1",
        link: "https://www.facebook.com/anandkumar2612",
        component: <FaFacebook/>,
      },
      {
        title:"ContactLink2",
        link: 'https://www.instagram.com/innocent_._idiot/',
        component: <FaInstagram/>,
      },
      {
        title:"ContactLink1",
        link: 'https://twitter.com/anandkumar2612',
        component: <FaTwitter/>,
      }
    ]})
  }
  render() {
    const activePage = []

    if(this.state.activePage === 0){
      activePage.push(<Home key={0}/>)
    }
    if(this.state.activePage === "Home"){
      activePage.push(<Home key={0}/>)
    }
    if(this.state.activePage === "About"){
      activePage.push(<About key={1} activeAbout={this.state.activeAbout} onClick={this.activeAbout} 
        personalDetails={this.state.personalDetails}/>)
    }
    if(this.state.activePage === "Gallery"){
      activePage.push(<Gallery key={2} onHover1={this.state.onHover1} onHover2={this.state.onHover2} onHover3={this.state.onHover3}
        onMouseEnter={this.onMouseEnter} onMouseOut={this.onMouseOut} activeGallery={this.state.activeGallery} 
        onClick={this.selectGallery} gallery1={this.state.gallery1} gallery2={this.state.gallery2} gallery3={this.state.gallery3}/>)
    }
    if(this.state.activePage === "Contact"){
      activePage.push(<Contact key={3} contactDetails={this.state.contactDetails}/>)
    }
    return (
      <div className='App'>
          <Header links={this.state.links} headings={this.state.headings} active={this.onClick}/>
          {activePage}
      </div>
    )
  }
}

export default App