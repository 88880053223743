import React from 'react'

class Home extends React.Component {
  render() {
    return (
        <div className='HomeContent'>
          <h1>Hello<br/>I'm AnandKumar</h1>
        </div>
    )
  }
}

export default Home